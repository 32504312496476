import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "navigationIcon", "mobileNavigation"]
  static classes = [ "active", "close" ]
  static values = {
    isActive: { type: Boolean, default: false }
  }

  connect() {}

  toggle() {
    this.isActiveValue = !this.isActiveValue;
  }

  isActiveValueChanged(value, previousValue) {
    if (this.isActiveValue) {
      this.open();
    } else {
      this.close();
    }
  }

  resize(event) {
    if (window.innerWidth > 768) {
      this.isActiveValue = false;
    }
  }

  open() {
    this.mobileNavigationTarget.classList.add(this.activeClass);
    this.navigationIconTarget.classList.add(this.closeClass);
  }

  close() {
    this.mobileNavigationTarget.classList.remove(this.activeClass);
    this.navigationIconTarget.classList.remove(this.closeClass);
  }
}
