// Entry point for the build script in your package.json
import "./controllers"

function disableGtm(event) {
  event.preventDefault();
  gtmOptOut();
  alert("Ihr Opt-Out Cookie wurde gesetzt!");
}

document.querySelectorAll(".disable-gtm").forEach(function (item) {
  item.addEventListener("click", disableGtm);
});
